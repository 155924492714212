import React, { useCallback, useState } from 'react';
import CSS from 'csstype';
import { usePublicGetArticles } from '~src/public/apigen/getArticles';
import { usePublicGetArticle } from '~src/public/apigen/getArticle';
import { usePublicGetSentence } from '~src/public/apigen/getSentence';
import { SideNav } from '~src/components/SideNav';

export const Articles: React.FC = () => {
  const [ articleID, setArticleID ] = useState<string>()
  const [ sentenceNumber, setSentenceNumber ] = useState<number>(0)
  const [ showTranslation, setShowTranslation ] = useState<boolean>(false)
  const [ switched, setSwitched ] = useState<boolean>(false)

  const props =  {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  }
  
  const { data: articles } = usePublicGetArticles({...props})
  const { data: article } = usePublicGetArticle({articleID: articleID ?? ""}, {...props})
  const sentenceID = article !== undefined && article.sentenceIDs ? article.sentenceIDs[sentenceNumber] ?? "" : ""
  const { data: sentence } = usePublicGetSentence({sentenceID}, {...props})

  const numberOfSentences = article ? article.sentenceIDs.length : 0

  const handlePageClick = async (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      await next();
    }
  };

  const back = useCallback(() => {
    if (sentenceNumber > 0) {
      setSentenceNumber(sentenceNumber - 1)
      setShowTranslation(false)
    }
  },[sentenceNumber])

  const next = useCallback(async () => {
    if (showTranslation) {
      setSentenceNumber(sentenceNumber + 1)
      setShowTranslation(false)
    } else {
      setShowTranslation(true)
    }
  }, [showTranslation, sentenceNumber]);

  return (
    <div onClick={handlePageClick} style={pageStyle}>
      {articles !== undefined && <SideNav articles={articles.articles} setArticleID={setArticleID} articleID={articleID}></SideNav>}
      {articles !== undefined && articleID !== undefined && (
        <>
          <div style={sentenceStyle}>
            {showTranslation && ( 
              <>
                {!switched && <div style={translationStyle}>{sentence?.translation}</div>}
                {switched && <div style={translationStyle}>{sentence?.text}</div>}
              </>        
            )}
            <div> 
              {!switched && sentence?.text}
              {switched && sentence?.translation}
            </div>
          </div>
          <div onClick={() => setSwitched(!switched)} style={switchStyle}>
            Switch
          </div>
          <div style={counterStyle}>
            {sentenceNumber + 1} / {numberOfSentences}
            <div style={backStyle} onClick={back}>
              back
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const translationStyle: CSS.Properties = {
  color: '#ADD8E6',
};

const switchStyle: CSS.Properties = {
  position: 'absolute',
  top: 0,
  right: 0,
  margin: '15px',
};

const counterStyle: CSS.Properties = {
  position: 'absolute',
  top: "20px",
  left: "50%",
  transform: "translate(-50%, -50%)",
  margin: '15px',
};

const backStyle: CSS.Properties = {
  position: 'absolute',
  top: 0,
  left: "-100px",
};

const sentenceStyle: CSS.Properties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const pageStyle: CSS.Properties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  cursor: 'pointer',
};