import React, { useEffect, useRef, useState } from 'react';
import CSS from 'csstype';


type LoginProps = {
  setPassword: React.Dispatch<React.SetStateAction<string>>;
};

export const Login: React.FC<LoginProps> = ({ setPassword }) => {
  const [inputValue, setInputValue] = useState('');

   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < 20) {
      setInputValue(e.target.value);
    }
  };

  function binary(count: number): string {
    let str = '';
    for (let i = 0; i < count; i++) {
      str += Math.random() < 0.5 ? '01' : '10';
    }
    return str;
  }

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      setPassword(inputValue)
    }
  };

  return (
    <div style={grid}>
      <div style={fullRow}>
        {Array.from({ length: 3 }, () => (
          <div style={binaryStyle}>{binary(60)}</div>
        ))}
      </div>
      <div style={row3}>
        <div style={binaryColumn}>
          {Array.from({ length: 13 }, () => (
            <div style={binaryStyle}>{binary(2)} {binary(2)}</div>
          ))}
        </div>
        <div style={emptyStyle}>
          <div>C:/ ENTER PASSWORD</div>
          <div style={inputContainer}>
          <input
            ref={inputRef}
            type="text"
            style={inputStyle}
            onKeyPress={handleKeyPress}
            onChange={handleInputChange}
            value={inputValue} // Bind the value to the input element
            onBlur={() => inputRef.current?.focus()} 
          />
            <div style={divInputStyle}>
            {inputValue}
            </div>
            <div style={cursorStyle}></div> {/* Custom cursor */}
          </div>
        </div>
        <div style={binaryColumn}>
          {Array.from({ length: 13 }, () => (
            <div style={binaryStyle}>{binary(2)} {binary(2)}</div>
          ))}
        </div>
      </div>
      <div style={fullRow}>
        {Array.from({ length: 3 }, () => (
          <div style={binaryStyle}>{binary(60)}</div>
        ))}
      </div>
    </div>
  );
};

const cursorStyle: CSS.Properties = {
  backgroundColor: 'transparent',
  width: '22px',
  marginLeft: '3px',
  marginTop: '2px',
  height: '36px',
  animation: 'blink 1s steps(2, start) infinite',
  borderBottom: '4px solid green',
};

const inputContainer: CSS.Properties = {
  display: 'flex',
  alignItems: 'center',
};

const inputStyle: CSS.Properties = {
  fontSize: '62px',
  color: 'transparent', // Hide the input text
  backgroundColor: 'transparent',
  border: 'none',
  outline: 'none',
  caretColor: 'transparent', // Hide the default cursor
  width: '0px',
};

const divInputStyle: CSS.Properties = {
  fontSize: '62px',
  color: 'green', // Hide the input text
  fontFamily: '"VT323", monospace',
  fontWeight: '400',
  backgroundColor: 'transparent',
  border: 'none',
  outline: 'none',
  caretColor: 'transparent', // Hide the default cursor
  textTransform: 'uppercase',
};

const binaryStyle: CSS.Properties = {
  fontSize: '16px',
  color: 'green',
  textAlign: 'center',
  fontFamily: '"VT323", monospace',
  fontWeight: '400',
  wordSpacing: '-3px',
};

const binaryColumn: CSS.Properties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const emptyStyle: CSS.Properties = {
  flex: 1,
  fontSize: '62px',
  color: 'green',
  fontFamily: '"VT323", monospace',
  fontWeight: '400',
  padding: '36px',
};

const grid: CSS.Properties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const fullRow: CSS.Properties = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  textAlign: 'center',
};

const row3: CSS.Properties = {
  display: 'flex',
  width: '100%',
};