import React, { useCallback, useEffect, useState } from 'react';
import CSS from 'csstype';
import { IPublicGetSimpsonsSentenceReturn, usePublicGetSimpsonsSentence } from '~src/public/apigen/getSimpsonsSentence';

export const SimpsonsSentence: React.FC = () => {
 const props =  {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  }
  
  const { data: sentence1, isValidating: isValidating1, mutate: mutate1 } = usePublicGetSimpsonsSentence({...props, refetchNonce: '1'})
  const { data: sentence2, isValidating: isValidating2, mutate: mutate2 } = usePublicGetSimpsonsSentence({...props, refetchNonce: '2'})
  const { data: sentence3, isValidating: isValidating3, mutate: mutate3 } = usePublicGetSimpsonsSentence({...props, refetchNonce: '3'})
  
  const [ sentence, setSentence ] = useState<IPublicGetSimpsonsSentenceReturn>()
  const [ initialLoad, setInitialLoad ] = useState<boolean>(true)
  const [ showTranslation, setShowTranslation ] = useState<boolean>(false)

  const handlePageClick = async (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      await next();
    }
  };

  console.log("SimpsonsSentence")

  useEffect(() => {
    if (initialLoad && !isValidating1) {
      setInitialLoad(false)
      setSentence(sentence1)
    }
    console.log(isValidating1)
  },[initialLoad, isValidating1, sentence1])

  const getSentence = useCallback(() => {
    if (!isValidating1) {
      setSentence(sentence1)
      mutate1()
    } else if (!isValidating2) {
      setSentence(sentence2)
      mutate2()
    } else if (!isValidating3) {
      setSentence(sentence3)
      mutate3()
    }
  },[isValidating1, isValidating2, isValidating3, mutate1, mutate2, mutate3, sentence1, sentence2, sentence3])
  
  const next = useCallback(async () => {
    if (showTranslation) {
      getSentence()
      setShowTranslation(false)
    } else {
      setShowTranslation(true)
    }
  }, [showTranslation, getSentence]);

  return (
    <div onClick={handlePageClick} style={pageStyle}>
      <div style={sentenceStyle}>
        {showTranslation && 
          <div style={translationStyle}>{sentence?.english}</div>
        }
        <div>
          {sentence?.spanish}
        </div>
      </div>
    </div>
  )
}

const translationStyle: CSS.Properties = {
  color: '#ADD8E6',
};

const sentenceStyle: CSS.Properties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const pageStyle: CSS.Properties = {
  position: "relative",
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  cursor: 'pointer',
};