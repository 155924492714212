import { isWindowDefined } from "~src/shared/helpers";
import { stringToBool } from "~src/shared/helpers/booleanCoercion";

const environments = ["production", "sandbox", "staging", "development", "test"] as const;

export type IEnvironmentType = typeof environments[number];

export interface IEnvironment {
  ENVIRONMENT: IEnvironmentType;
  DOMAIN: string;

  // optional
  MODE: "maintenance" | null;
}

/**
 * Makes the environment from environment variables. This function ONLY WORKS server side.
 *
 * The server calls this in `makeGetServerSideProps`.
 */
export const makeEnv = (): IEnvironment => {
  if (typeof window !== "undefined") {
    throw new Error("Tried to call makeEnv client side");
  }

  const requiredRaw = {
    ENVIRONMENT: process.env.ENVIRONMENT,
    DOMAIN: process.env.DOMAIN,
  };

  // Throw error if any required environment variables are not set.
  Object.entries(requiredRaw).forEach(([key, value]) => {
    if (!stringToBool(value)) {
      throw new Error(`env: ${key} not set, fatal`);
    }
  });

  const required = requiredRaw as { [K in keyof typeof requiredRaw]: string };

  const optional = {
    MODE: process.env.MODE ?? null,
  };

  // validation
  const { ENVIRONMENT } = required;
  if (!(environments as readonly string[]).includes(ENVIRONMENT)) {
    throw new Error(`Invalid environment: ${ENVIRONMENT}`);
  }

  const { MODE } = optional;
  if (MODE !== null && MODE !== "maintenance") {
    throw new Error(`Invalid mode: ${optional.MODE}`);
  }

  const env = {
    ...required,
    ...optional,

    ENVIRONMENT: ENVIRONMENT as IEnvironmentType,

    // optional
    MODE: MODE as "maintenance" | null,
  };


  return {
    ...env,
  };
};

/**
 * Gets the URL of the API associated with the given domain.
 */
const getAPIURLOfDomain = (domain: string): string => {
  // Production
  if (domain === "www.graycurtis.com" || domain === "graycurtis.com") {
    return "https://graycurtis.fly.dev";
  }

  // Local development (override)
  if (process.env.NEXT_PUBLIC_API_URL__LOCAL_ONLY !== undefined) {
    return process.env.NEXT_PUBLIC_API_URL__LOCAL_ONLY;
  }

  // E2E tests & fully local dev.
  if (domain === "localhost") {
    return "http://localhost:8080";
  }
  throw new Error("API_URL could not be calculated.");
};

/**
 * The URL of the API server.
 */
export const makeAPIURL = (): string =>
  isWindowDefined
    ? getAPIURLOfDomain(window.location.hostname)
    : getAPIURLOfDomain(makeEnv().DOMAIN);
