import { makeAPIURL } from "./../env";
import {
  InternalServerError,
  IRPCErrorOptions,
  NetworkError,
  RPCError,
  UnknownRPCError,
} from "../errors/taxonomy";
import { SHARED_ERRORS } from "~src/shared/apigen/errors";

interface ErrorClass {
  new (message: string, options: IRPCErrorOptions): RPCError;
}

type IErrors = { code: string; ErrorClass: ErrorClass }[];

type IParams<ReqData> = {
  router: string;
  routePath: string;
  args: ReqData;
  errors: IErrors;
  method: "GET" | "POST" | "PATCH" | "PUT" | "DELETE";
};

export const fetchRPCRoute = async <ReqData, RespData>({
  router,
  routePath,
  args,
  errors,
  method,
}: IParams<ReqData>): Promise<RespData> => {
  // Construct the headers.
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };

  let response;
  let text;
  try {
    console.log(makeAPIURL() + `/${router}/${routePath}`)
    response = await fetch(makeAPIURL() + `/${router}/${routePath}`, {
      method,
      cache: "no-store",
      credentials: "include",
      headers,
      body: args === undefined ? undefined : JSON.stringify(args),
    });
    text = await response.text();
  } catch (e) {
    // The Promise returned from fetch() won't reject on HTTP error status even if the
    // response is an HTTP 404 or 500. Instead, as soon as the server responds with
    // headers, the Promise will resolve normally (with the ok property of the response
    // set to false if the response isn't in the range 200–299), and it will only reject
    // on network failure or if anything prevented the request from completing.
    throw new NetworkError(`Network error: ${e}`, { router, routePath });
  }

  if (response.status === 404) {
    const error = new UnknownRPCError(`Unknown route ${router}/${routePath}.`, {
      status: response.status,
      data: {},
      router,
      routePath,
    });
    throw error;
  }

  let data;
  try {
    data = JSON.parse(text).data;
  } catch (e) {
    const error = new UnknownRPCError("Failed to decode JSON.", {
      status: response.status,
      data: {},
      router,
      routePath,
    });
    throw error;
  }

  try {
    // Deserialize currency fields with Money class
    data = deserialize(data);
  } catch (e) {
    const error = new CurrencyDeserializationError("Failed to deserialize currency data.", {
      status: response.status,
      data: {},
      router,
      routePath,
    });
    throw error;
  }

  // First handle the generalized errors.
  const statusType = Math.floor(response.status / 100);
  if (statusType === 5) {
    throw new InternalServerError(text, { router, routePath });
  }

  // Then handle the expected backend errors. All have a 400 status code.
  if (statusType !== 2) {
    const errorData = data as { code?: string };

    // These are the shared errors, typically from middlewares.
    for (const { code, ErrorClass } of SHARED_ERRORS) {
      if (errorData.code === code) {
        throw new ErrorClass(errorData.code, { router, routePath, status: response.status });
      }
    }

    // These are the request-specific errors.
    for (const { code, ErrorClass } of errors) {
      if (errorData.code === code) {
        throw new ErrorClass(errorData.code, {
          router,
          routePath,
          status: response.status,
          data: errorData,
        });
      }
    }

    // If we still haven't classified the error, throw an Unknown RPC error.
    const error = new UnknownRPCError("An unknown RPC error occurred.", {
      status: response.status,
      data: errorData,
      router,
      routePath,
    });
    throw error;
  }

  return data as RespData;
};

type ICurrencySentinel =
  | {
      __sentinel: "Money" | undefined;
      amount: number;
      code: string;
    }
  | unknown;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IInput = { [key: string]: ICurrencySentinel | IInput | any };

export const deserialize = (obj: IInput): IInput => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (Array.isArray(obj)) {
    return obj.map(deserialize);
  }
  if (
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    obj !== null &&
    typeof obj === "object" &&
    // eslint-disable-next-line no-prototype-builtins
    Boolean(Object.getPrototypeOf(obj).isPrototypeOf(Object))
  ) {
    return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, deserialize(v)]));
  }

  return obj;
};

class CurrencyDeserializationError extends RPCError {
  static code = "SHARED/BASE/CURRENCY_DESERIALIZATION_ERROR";
}
