// Code generated by go run pkg/apigen/generate. DO NOT EDIT.

import useSWR, { SWRConfiguration, SWRResponse } from "swr";

import * as enums from "~src/shared/enums";
import * as routerTypes from "~src/public/apigen/types";
import * as globalTypes from "~src/shared/apigen/types";
import { fetchRPCRoute } from "~src/shared/apigen/framework";
import { FieldValidationError, RPCError, IRPCErrorOptions } from "~src/shared/errors/taxonomy";

export type IPublicGetSimpsonsSentenceReturn = {
  spanish: string;
  english: string;
};

export const publicGetSimpsonsSentence = async (
): Promise<IPublicGetSimpsonsSentenceReturn> => {
  return await fetchRPCRoute({
    method: "POST",
    router: "public",
    routePath: "GetSimpsonsSentence",
    args: undefined,
    errors: [
    ],
  });
};

export const usePublicGetSimpsonsSentence = (
  config?: SWRConfiguration & {
    // If true, the hook will not fetch. Data will be null.
    blockFetch?: boolean,
    // This value will be included in the cache key. Pass different values to force cache
    // invalidation.
    refetchNonce?: number | string,
  },
): SWRResponse<IPublicGetSimpsonsSentenceReturn, RPCError> => {
  return useSWR(
    config?.blockFetch === true ? null : ["/public/GetSimpsonsSentence", config?.refetchNonce],
    publicGetSimpsonsSentence,
    config,
  );
};
