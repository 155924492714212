import React, { useState } from 'react';
import CSS from 'csstype';
import { SimpsonsSentence } from './pages/SimpsonsSentence';
import { Login } from './pages/Login';
import { Articles } from './pages/Articles';

function App() {
  const [password, setPassword] = useState("")

  const component = password === "guest" ? <SimpsonsSentence /> : password === "brew" ?  <Articles /> : <Login setPassword={setPassword}/>

  return (
    <div style={style}>
      {component}
    </div>
  )
}

export default App;

const style: CSS.Properties = {
  backgroundColor: '#181818',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'calc(10px + 2vmin)',
  color: '#bbb',
};