import React from 'react';
import CSS from 'csstype';
import { IGetArticlesArticle } from '~src/public/apigen/getArticles';

type SideNavProps = {
    setArticleID: React.Dispatch<React.SetStateAction<string>>;
    articles: IGetArticlesArticle[];
    articleID: string | undefined;
};

export const SideNav: React.FC<SideNavProps> = ({articles, setArticleID, articleID}) => {
    return (
        <div>
            {articles.map((article) => {
                return (
                    <div key={article.id} style={articleStyle} onClick={() => setArticleID(article.id)}>
                        {article.key}
                    </div>
                )
            })}
        </div>
    )
}

const articleStyle: CSS.Properties = {
    margin: "10px",
    border: "2px solid black"
};

const selectedArticleStyle: CSS.Properties = {
    ...articleStyle,
    margin: "10px",
    border: "2px solid black"
};