// Code generated by go run pkg/apigen/generate. DO NOT EDIT.

import {
  FieldValidationError,
  RPCError,
  IRPCErrorOptions,
} from "~src/shared/errors/taxonomy";

export class LoginRequired extends RPCError {
  static code = "SHARED/RPC/LOGIN_REQUIRED";
  constructor(message: string, options: IRPCErrorOptions) {
    super(message, {
      ...options,
      expected: true,
      transient: true,
      status: 401,
    });
  }
}

export class MissingPermission extends RPCError {
  static code = "SHARED/RPC/MISSING_PERMISSION";
  constructor(message: string, options: IRPCErrorOptions) {
    super(message, {
      ...options,
      expected: true,
      transient: false,
      status: 403,
    });
  }
}

export class UnmarshalFailure extends RPCError {
  static code = "SHARED/RPC/UNMARSHAL_FAILURE";
  constructor(message: string, options: IRPCErrorOptions) {
    super(message, {
      ...options,
      expected: false,
      transient: false,
      status: 400,
    });
  }
}

export class MissingResource extends RPCError {
  static code = "SHARED/RPC/MISSING_RESOURCE";
  constructor(message: string, options: IRPCErrorOptions) {
    super(message, {
      ...options,
      expected: true,
      transient: false,
      status: 400,
    });
  }
}

export class Unimplemented extends RPCError {
  static code = "SHARED/RPC/UNIMPLEMENTED";
  constructor(message: string, options: IRPCErrorOptions) {
    super(message, {
      ...options,
      expected: true,
      transient: false,
      status: 400,
    });
  }
}

export class BlockedIP extends RPCError {
  static code = "SHARED/RPC/BLOCKED_IP";
  constructor(message: string, options: IRPCErrorOptions) {
    super(message, {
      ...options,
      expected: true,
      transient: false,
      status: 403,
    });
  }
}

export class NotAllowedInProd extends RPCError {
  static code = "SHARED/RPC/NOT_ALLOWED_IN_PROD";
  constructor(message: string, options: IRPCErrorOptions) {
    super(message, {
      ...options,
      expected: true,
      transient: false,
      status: 400,
    });
  }
}

export class UnsupportedVertical extends RPCError {
  static code = "SHARED/RPC/UNSUPPORTED_VERTICAL";
  constructor(message: string, options: IRPCErrorOptions) {
    super(message, {
      ...options,
      expected: true,
      transient: false,
      status: 400,
    });
  }
}

export const SHARED_ERRORS = [
  { code: "SHARED/RPC/LOGIN_REQUIRED", ErrorClass: LoginRequired },
  { code: "SHARED/RPC/MISSING_PERMISSION", ErrorClass: MissingPermission },
  { code: "SHARED/RPC/UNMARSHAL_FAILURE", ErrorClass: UnmarshalFailure },
  { code: "SHARED/RPC/MISSING_RESOURCE", ErrorClass: MissingResource },
  { code: "SHARED/RPC/UNIMPLEMENTED", ErrorClass: Unimplemented },
  { code: "SHARED/RPC/BLOCKED_IP", ErrorClass: BlockedIP },
  { code: "SHARED/RPC/NOT_ALLOWED_IN_PROD", ErrorClass: NotAllowedInProd },
  { code: "SHARED/RPC/UNSUPPORTED_VERTICAL", ErrorClass: UnsupportedVertical },
];
